import { environment } from 'src/app/common/constants/constants';
import { EnumRoleUser } from '../common/enums/enum-role';

export function decodeUserTraits(access_token: any) {
	if (access_token) {
		return decodeURIComponent(window.atob(access_token));
	}
	return '';
}

export function getUserStraits() {
	const userStraits = localStorage.getItem('user_traits');
	if (userStraits) {
		return JSON.parse(decodeUserTraits(localStorage.getItem('user_traits')));
	}
	return null;
}

export function getUserInfo() {
	const userInfo = getUserStraits();
	return userInfo;
}

export function getUserInfoByToken(traits: any) {
	return JSON.parse(decodeUserTraits(traits));
}

export function IsInRole(user: any, roles: Array<string> | string): boolean {
	let check = false;
	/// Trả về giá trị true khi user có ít nhất một role thỏa mãn
	if (user && user.Roles) {
		if (Array.isArray(user.Roles)) {
			user.Roles.forEach((userrole: string) => {
				if (roles.includes(userrole)) {
					check = true;
				}
			});
		} else {
			return roles.includes(user.Roles);
		}
	}
	return check;
}

export function isSuperAdmin(): boolean {
	const userInfo = getUserInfo();
	return IsInRole(userInfo, EnumRoleUser.SUPER_ADMIN);
}

export function isAdmin(): boolean {
	const userInfo = getUserInfo();
	return IsInRole(userInfo, EnumRoleUser.ADMIN_SITE);
}

export function isManager(): boolean {
	const userInfo = getUserInfo();
	return IsInRole(userInfo, EnumRoleUser.COMPANY_MANAGER);
}

export function isStaff(): boolean {
	const userInfo = getUserInfo();
	return IsInRole(userInfo, EnumRoleUser.STAFF);
}

export function isAuthenticated() {
	const token = localStorage.getItem('token');
	return token != null;
}

export function getToken(): string | null {
	return localStorage.getItem('token');
}

export function logOut() {
	localStorage.removeItem('token');
	localStorage.removeItem('user_traits');
	window.location.href = '/login';
	return;
}

export function IsLanguage(language) {
	return localStorage.getItem('language') === language;
}

export function isProduction() {
	return environment.production;
}
