export enum EnumRoleUser {
	SUPER_ADMIN = 'SUPERADMIN',
	ADMIN = 'ADMIN',
	ADMIN_SITE = 'ADMIN_SITE',
	COMPANY_MANAGER = 'COMPANY_MANAGER',
	STAFF = 'STAFF',
}

export enum EnumCompanyRole {
	// Admin công ty
	ADMIN = 1,
	// Trưởng phòng
	MANAGER = 2,
	// Nhân viên
	STAFF = 3,
}
